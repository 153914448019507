@import "./mixins.scss";

.search-input-group {
  position: relative;
  display: flex;
  align-items: center;

  .btn {
    position: absolute;
    right: 0;
  }
}

.search-input-lg {
  color: var(--off_black);
  font-size: 64px;
  border: 0;
  border-bottom: 2px solid var(--gray);
  padding: 24px 200px 24px 0;

  @include input-placeholder {
    color: rgba(38, 38, 38, 0.24);
  }
}

.searches-list {
  li {
    display: inline-flex;
    padding-right: 24px;
    width: 50%;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  li button {
    font-family: var(--font-stack-med);
    text-decoration: underline;
    text-align: left;
  }
}

.search-result {
  width: 100%;
  padding: 24px 0;
  transition: var(--default-transition);

  &:not(:last-child) {
    border-bottom: 1px solid var(--gray);
  }

  &:hover,
  &:focus {
    opacity: 0.65;

    .result-title {
      text-decoration: underline;
    }
  }

  &.defined-term {
    padding: 24px 104px;
    transition: none;

    &:hover,
    &:focus {
      opacity: 1;

      .result-title {
        text-decoration: none;
      }
    }

    label {
      cursor: text;
    }
  }

  .indented {
    > p {
      width: 120px;
      min-width: 120px;
      text-align: right;
      margin-right: 24px;
    }
  }

  .result-text {
    text-align: left;

    strong,
    em {
      font-family: var(--font-stack-bld);
      color: var(--off_black);
    }
  }
}

@include mq("tablet") {
  .search-input-lg {
    font-size: 36px;
  }
}
