@import "./mixins.scss";

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  font-size: 14px;
  font-family: var(--font-stack-bld);
  border-radius: 6px;
  padding: 12px 24px;
  text-decoration: none;
  transition: var(--default-transition);
  white-space: nowrap;
  text-align: center;
  cursor: pointer;

  &:focus,
  &:hover {
    transform: scale(1.04);
  }

  &:disabled,
  &[disabled] {
    opacity: 0.65;
    cursor: not-allowed;

    &:focus,
    &:hover {
      transform: scale(1);
    }
  }

  svg {
    transition: var(--default-transition);

    path {
      transition: var(--default-transition);
    }
  }
}

.btn-primary {
  color: var(--white);
  background-color: var(--brand);

  .icon-circle {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.btn-blue {
  color: var(--white);
  background-color: var(--blue);

  .icon-circle {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.btn-secondary {
  color: var(--md_gray);
  background-color: var(--lt_gray);

  &:hover,
  &:focus {
    color: var(--dk_gray);

    svg path {
      fill: var(--dk_gray);
    }
  }

  svg path {
    fill: var(--md_gray);
  }

  .icon-circle {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.btn-ghost {
  color: rgba(255, 255, 255, 0.65);
  background-color: rgba(255, 255, 255, 0.12);

  &:hover,
  &:focus {
    color: var(--white);

    svg {
      opacity: 1;

      path {
        fill: var(--white);
      }
    }
  }

  svg {
    opacity: 0.65;

    path {
      fill: var(--white);
    }
  }
}

.btn-dk-ghost {
  color: rgba(255, 255, 255, 0.8);
  background-color: rgba(38, 38, 38, 0.65);

  &:hover,
  &:focus {
    color: var(--white);

    svg {
      opacity: 1;

      path {
        fill: var(--white);
      }
    }
  }

  svg {
    opacity: 0.8;

    path {
      fill: var(--white);
    }
  }
}

.btn-warning {
  color: var(--md_gray);
  background-color: var(--pink);
  border: 2px solid var(--lt_red);

  &:hover,
  &:focus {
    color: var(--dk_gray);
    background-color: var(--lt_red);

    svg path {
      fill: var(--dk_gray);
    }
  }

  svg path {
    fill: var(--md_gray);
  }
}

.btn-outline {
  color: var(--md_gray);
  background-color: transparent;
  border: 2px solid var(--lt_gray);

  &:hover,
  &:focus {
    color: var(--dk_gray);
    background-color: var(--lt_gray);
    border-color: var(--gray);

    svg path {
      fill: var(--dk_gray);
    }
  }

  svg path {
    fill: var(--md_gray);
  }
}

.btn-sm {
  height: 24px;
  font-size: 12px;
  padding: 4px 12px;

  &.btn-sq {
    justify-content: center;
    width: 24px;
    padding: 0;
  }
}

.btn-md {
  height: 32px;
  font-size: 13px;
  padding: 6px 12px;

  &.btn-sq {
    justify-content: center;
    width: 32px;
    padding: 0;
  }
}

.btn-lg {
  height: 64px;
  font-size: 16px;
  padding: 16px 32px;

  svg {
    transform: scale(1.2);
  }

  &.btn-sq {
    justify-content: center;
    width: 64px;
    padding: 0;
  }
}

.btn-block {
  width: 100%;
}

.btn-circle {
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 0;
}

.btn-circle-xs {
  @include size(32px);
}

.btn-circle-sm {
  @include size(40px);
}

.btn-circle-md {
  @include size(48px);
}

.btn-circle-lg {
  @include size(72px);
}

.icon-circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
