.media-section-group {
  margin-bottom: 32px;

  &:last-child,
  &:last-of-type {
    margin-bottom: 0;
  }
}

.media-group {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  &:last-child,
  &:last-of-type {
    margin-bottom: 0;
  }

  .media-avatar {
    display: flex;
    position: relative;
    margin-right: 12px;
    border-radius: 6px;
    box-shadow: var(--default-shadow);
    transition: var(--default-transition);
    overflow: hidden;

    &:hover,
    &:focus {
      box-shadow: var(--default-lifted-shadow);
    }

    &:hover,
    &:focus {
      .btn {
        transform: scale(1.04);

        svg {
          opacity: 1;

          path {
            fill: var(--white);
          }
        }
      }
    }

    &.video {
      width: 80px;
      min-width: 80px;
      height: 56px;
      min-height: 56px;
      background-color: var(--off_black);
    }

    &.pdf {
      width: 80px;
      min-width: 80px;
      height: 104px;
      min-height: 104px;
    }

    .cover {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9;
    }

    img {
      width: 100%;
      border-radius: 6px;
      object-fit: cover;
    }
  }

  .media-content {
    label.sm {
      color: var(--dk_gray);
      cursor: text;
    }
  }
}
