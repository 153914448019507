.book {
  --color: #fff;
  --duration: 6.8s;
  position: relative;
  width: 32px;
  height: 12px;
  margin: 32px 0 0 0;

  .inner {
    position: relative;
    width: 32px;
    height: 12px;
    transform-origin: 2px 2px;
    transform: rotateZ(-90deg);
    animation: book var(--duration) ease infinite;

    .left,
    .right {
      position: absolute;
      top: 0;
      width: 60px;
      height: 4px;
      background: var(--color);
      border-radius: 2px;

      &:before {
        content: "";
        position: absolute;
        top: -10px;
        left: 6px;
        width: 48px;
        height: 4px;
        background: inherit;
        border-radius: 2px;
      }
    }

    .left {
      right: 28px;
      transform-origin: 58px 2px;
      transform: rotateZ(90deg);
      animation: left var(--duration) ease infinite;
    }

    .right {
      left: 28px;
      transform-origin: 2px 2px;
      transform: rotateZ(-90deg);
      animation: right var(--duration) ease infinite;
    }

    .middle {
      width: 32px;
      height: 12px;
      border: 4px solid var(--color);
      border-top: 0;
      border-radius: 0 0 9px 9px;
      transform: translateY(2px);
    }
  }

  ul {
    position: absolute;
    left: 50%;
    top: 0;
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      position: absolute;
      top: -10px;
      right: 0;
      height: 4px;
      width: 48px;
      background: var(--color);
      border-radius: 2px;
      transform-origin: 100% 2px;
      transform: rotateZ(0deg) translateX(-18px);
      animation-duration: var(--duration);
      animation-timing-function: ease;
      animation-iteration-count: infinite;

      $i: 0;
      @while $i < 19 {
        &:nth-child(#{$i}) {
          animation-name: page-#{$i};
        }
        $i: $i + 1;
      }
    }
  }
}

$i: 0;
@while $i < 19 {
  $delay: $i * 1.86;
  $delay-after: $i * 1.74;
  @keyframes page-#{$i} {
    #{4 + $delay}% {
      transform: rotateZ(0deg) translateX(-18px);
    }
    #{13 + $delay-after}%,
    #{54 + $delay}% {
      transform: rotateZ(180deg) translateX(-18px);
    }
    #{63 + $delay-after}% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }
  $i: $i + 1;
}

@keyframes left {
  4% {
    transform: rotateZ(90deg);
  }
  10%,
  40% {
    transform: rotateZ(0deg);
  }
  46%,
  54% {
    transform: rotateZ(90deg);
  }
  60%,
  90% {
    transform: rotateZ(0deg);
  }
  96% {
    transform: rotateZ(90deg);
  }
}

@keyframes right {
  4% {
    transform: rotateZ(-90deg);
  }
  10%,
  40% {
    transform: rotateZ(0deg);
  }
  46%,
  54% {
    transform: rotateZ(-90deg);
  }
  60%,
  90% {
    transform: rotateZ(0deg);
  }
  96% {
    transform: rotateZ(-90deg);
  }
}

@keyframes book {
  4% {
    transform: rotateZ(-90deg);
  }
  10%,
  40% {
    transform: rotateZ(0deg);
    transform-origin: 2px 2px;
  }
  40.01%,
  59.99% {
    transform-origin: 30px 2px;
  }
  46%,
  54% {
    transform: rotateZ(90deg);
  }
  60%,
  90% {
    transform: rotateZ(0deg);
    transform-origin: 2px 2px;
  }
  96% {
    transform: rotateZ(-90deg);
  }
}

.loader-full-bg {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.loader-full-height {
  position: fixed;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 999;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--brand);
  animation: blast var(--animation-duration, 1s) infinite ease-out;

  &-sm {
    width: 24px;
    height: 24px;
  }

  &-lg {
    width: 96px;
    height: 96px;
  }

  &-white {
    background-color: var(--white);
  }
}

@keyframes blast {
  0% {
    opacity: 1;
    transform: scale(0.1);
  }

  100% {
    transform: scale(1);
    opacity: 0.1;
  }
}
