@font-face {
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins-Regular"), url("../assets/fonts/Poppins-Regular.woff") format("woff");
}

@font-face {
  font-family: "Poppins-Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins-Italic"), url("../assets/fonts/Poppins-Italic.woff") format("woff");
}

@font-face {
  font-family: "Poppins-Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins-Medium"), url("../assets/fonts/Poppins-Medium.woff") format("woff");
}

@font-face {
  font-family: "Poppins-MediumItalic";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins-MediumItalic"), url("../assets/fonts/Poppins-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: "Poppins-SemiBold";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins-SemiBold"), url("../assets/fonts/Poppins-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Poppins-SemiBoldItalic";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins-SemiBoldItalic"), url("../assets/fonts/Poppins-SemiBoldItalic.woff") format("woff");
}
