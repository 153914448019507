@import "./mixins.scss";

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-stack-bld);
}

h1.jumbo {
  font-size: 88px;
  line-height: 88px;
}

h1 {
  font-size: 64px;
  line-height: 1;
}

h2 {
  font-size: 48px;
  line-height: 1.2;
}

h3 {
  font-size: 36px;
  line-height: 1.2;
}

h4 {
  font-size: 28px;
  line-height: 1.2;
}

h5 {
  font-size: 22px;
  line-height: 1.3;
}

h6 {
  font-size: 18px;
  line-height: 1.4;
}

p {
  line-height: 1.3;
  font-family: var(--font-stack-med);
}

p.sm {
  font-size: 14px;
  line-height: 1.2;
}

p.xs {
  font-size: 12px;
  line-height: 1.3;
}

p.title {
  font-size: 14px;
  letter-spacing: 12%;
}

p.lead {
  color: var(--dk_gray);
  font-size: 16px;
}

p.lead-sm {
  color: var(--dk_gray);
  font-size: 14px;
  line-height: 1.6;
}

p.lead-md {
  color: var(--dk_gray);
  font-size: 19px;
}

p.lead-lg {
  color: var(--dk_gray);
  font-size: 24px;
}

label {
  font-size: 13px;
  font-family: var(--font-stack-med);
}

label.sm {
  font-size: 11px;
  line-height: 1.4;
}

@include mq("tablet") {
  h1.jumbo {
    font-size: 48px;
    line-height: 48px;
  }

  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 22px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 15px;
  }

  h6 {
    font-size: 13px;
  }

  p {
    font-family: var(--font-stack-med);
    line-height: 1.3;
  }

  p.sm {
    font-size: 13px;
    line-height: 1.2;
  }

  p.title {
    font-size: 13px;
    letter-spacing: 8%;
  }

  p.lead {
    color: var(--dk_gray);
    font-size: 14px;
  }

  p.lead-sm {
    color: var(--dk_gray);
    font-size: 12px;
    line-height: 1.6;
  }

  p.lead-md {
    color: var(--dk_gray);
    font-size: 15px;
  }

  p.lead-lg {
    color: var(--dk_gray);
    font-size: 18px;
  }

  label {
    font-size: 12px;
    font-family: var(--font-stack-med);
  }

  label.sm {
    font-size: 11px;
    font-family: var(--font-stack-med);
  }
}
