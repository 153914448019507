@import "./mixins.scss";

.spacer {
  &-4 {
    @include size(4px);
  }

  &-8 {
    @include size(8px);
  }

  &-12 {
    @include size(12px);
  }

  &-16 {
    @include size(16px);
  }

  &-20 {
    @include size(20px);
  }

  &-24 {
    @include size(24px);
  }

  &-28 {
    @include size(28px);
  }

  &-32 {
    @include size(32px);
  }

  &-40 {
    @include size(40px);
  }

  &-44 {
    @include size(44px);
  }

  &-48 {
    @include size(48px);
  }

  &-56 {
    @include size(56px);
  }

  &-64 {
    @include size(64px);
  }

  &-72 {
    @include size(72px);
  }

  &-80 {
    @include size(80px);
  }

  &-88 {
    @include size(88px);
  }

  &-96 {
    @include size(96px);
  }

  &-104 {
    @include size(104px);
  }

  &-112 {
    @include size(112px);
  }

  &-120 {
    @include size(120px);
  }

  &-128 {
    @include size(128px);
  }

  &-136 {
    @include size(136px);
  }

  &-144 {
    @include size(144px);
  }

  &-152 {
    @include size(152px);
  }

  &-160 {
    @include size(160px);
  }

  &-168 {
    @include size(168px);
  }
}
