@import "./mixins.scss";

html,
body,
#root {
  height: 100vh;
}

body {
  color: var(--off_black);
  font-family: var(--font-stack-reg);
  margin: 0;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

button,
input,
select,
textarea {
  font-family: var(--font-stack-reg);
}

.blur-bg-white {
  background-color: var(--white);
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .blur-bg-white {
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    background-color: rgba(255, 255, 255, 0.65);
  }
}

.circle-number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  font-family: var(--font-stack-bld);
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  margin-right: 12px;
  text-align: center;
}

.tooltip-sm {
  font-family: var(--font-stack-med) !important;
  border-radius: 6px !important;
  padding: 6px 12px !important;
}

.truncate {
  @include truncate(100%);
}

.error {
  display: flex;
  align-items: center;
  font-family: var(--font-stack-med);
  font-size: 14px;
  line-height: 1.2;
  color: var(--red);
  margin: 12px 0;

  svg {
    margin-right: 8px;
  }
}

.error-item {
  &:not(:last-child) {
    margin-bottom: 24px;
  }

  .error-type,
  .error-message {
    color: var(--white);
  }

  .error-type {
    font-size: 17px;
    margin-bottom: 8px;
  }

  .error-message {
    font-size: 13px;
  }
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-full-height {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.flex-1 {
  flex: 1 !important;
}

.flex-2 {
  flex: 2 !important;
}

.space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.flex-column {
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.align-top {
  align-items: flex-start;
}

.align-bottom {
  align-items: flex-end;
}

.align-baseline {
  align-items: baseline;
}

.blue-link {
  color: var(--blue);
  font-size: 14px;
  line-height: 20px;
  transition: var(--default-transition);

  &:hover,
  &:focus {
    color: var(--brand);
    text-decoration: underline;
  }
}

.info-well {
  display: flex;
  border: 1px solid var(--gray);
  border-radius: 6px;
  padding: 8px 12px;

  svg {
    margin-right: 12px;
  }

  label {
    color: var(--md_gray);
  }
}

.dots {
  display: none;
  position: fixed;

  &.top {
    top: 0;
  }

  &.right {
    right: 0;
  }

  &.bottom {
    bottom: 0;
  }

  &.left {
    left: 0;
  }
}

.bullet-list {
  color: var(--dk_gray);
  margin-left: 24px;

  li {
    list-style: disc;
  }
}

img {
  @include unselectable;
}

.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
}

a {
  font-family: var(--font-stack-med);
  text-decoration: none;
}

.divider-lg {
  background-color: var(--lt_blue);
  height: 6px;
}

.full {
  @include size(100%);
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.c-brand {
  color: var(--brand) !important;
}

.c-blue {
  color: var(--blue) !important;
}

.c-lt_blue {
  color: var(--lt_blue) !important;
}

.c-md_blue {
  color: var(--md_blue) !important;
}

.c-red {
  color: var(--red) !important;
}

.c-green {
  color: var(--green) !important;
}

.c-black {
  color: var(--black) !important;
}

.c-off_black {
  color: var(--off_black) !important;
}

.c-white {
  color: var(--white) !important;
}

.c-red {
  color: #ff4040 !important;
}

.c-off_white {
  color: var(--off_white) !important;
}

.c-lt_gray {
  color: var(--lt_gray) !important;
}

.c-gray {
  color: var(--gray) !important;
}

.c-md_gray {
  color: var(--md_gray) !important;
}

.c-dk_gray {
  color: var(--dk_gray) !important;
}

.c-gray_blue {
  color: var(--gray_blue) !important;
}

.op-12 {
  opacity: 0.12 !important;
}

.op-16 {
  opacity: 0.16 !important;
}

.op-24 {
  opacity: 0.24 !important;
}

.op-40 {
  opacity: 0.4 !important;
}

.op-65 {
  opacity: 0.65 !important;
}

.op-80 {
  opacity: 0.8 !important;
}

.op-100 {
  opacity: 1 !important;
}

.font-reg {
  font-family: var(--font-stack-reg) !important;
}

.font-italic {
  font-family: var(--font-stack-itl) !important;
}

.font-med {
  font-family: var(--font-stack-med) !important;
}

.font-med-italic {
  font-family: var(--font-stack-medItl) !important;
}

.font-bold {
  font-family: var(--font-stack-bld) !important;
}

.font-bold-italic {
  font-family: var(--font-stack-bldItl) !important;
}

.cur-pointer {
  cursor: pointer;
}

.divider {
  height: 1px;
  background-color: var(--md_gray);
}

.tdu {
  text-decoration: underline;
}

.no-padding {
  padding: 0 !important;
}

.show-tablet {
  display: none !important;
}

@media only screen and (max-width: 992px) {
  .show-tablet {
    display: flex !important;
  }

  .hide-tablet {
    display: none !important;
  }
}
