.standards-view {
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 100vh;
  overflow: hidden;

  @media only screen and (max-width: 992px) {
    display: block;
  }
}

.effective-date {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: fixed;
  top: 24px;
  left: 50%;
  height: 32px;
  font-family: var(--font-stack-med);
  font-size: 14px;
  line-height: 1.6;
  color: var(--white);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background-color: rgba(38, 38, 38, 0.65);
  border-radius: 6px;
  margin-bottom: 12px;
  padding: 4px 12px;
  transform: translateX(-50%);
  z-index: 999;
}

.floating-panel {
  position: absolute;
  top: 128px;
  right: 24px;
  width: 380px;
  min-width: 380px;
  max-width: 600px;
  max-height: 600px;
  min-height: 168px;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0px 0px 144px 64px rgba(38, 38, 38, 0.06);
  resize: both;
  overflow: auto;
  z-index: 103;

  .menu-bar {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    background-color: var(--brand);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 8px 8px 8px 16px;
    cursor: move;

    p {
      color: var(--white);
      font-family: var(--font-stack-bld);
    }

    .btn:not(:first-child) {
      margin-left: 4px;
    }

    .btn {
      &:hover,
      &:focus {
        svg {
          opacity: 1;
        }
      }

      svg {
        opacity: 0.65;
        transition: var(--default-transition);
      }
    }
  }

  .panel-content {
    padding: 24px;
    overflow: auto;

    .content-title {
      font-size: 14px;
      font-family: var(--font-stack-bld);
      color: var(--off_black);
      margin-bottom: 16px;
    }

    > div {
      display: flex;
      align-items: baseline;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      .ref {
        flex-direction: column;
        align-items: flex-end;
        min-width: max-content;
        font-size: 12px;
        font-family: var(--font-stack-bld);
        color: var(--off_black);
        text-align: right;
        margin-right: 16px;
      }

      p {
        color: var(--dk_gray);
        font-size: 13px;
        line-height: 1.5;
      }
    }
  }
}

.nav-toc,
.side-content {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 320px;
  transition: var(--long-transition);

  .loader-full-bg {
    top: 0;
    width: 320px;
  }

  @media only screen and (max-width: 1248px) {
    min-width: 260px;
    width: 260px;

    .loader-full-bg {
      width: 260px;
    }
  }

  @media only screen and (max-width: 992px) {
    position: fixed !important;
    top: 0;
    bottom: 0;
    height: 100vh;
    background-color: var(--white);
    box-shadow: 0px 0px 144px 64px rgba(38, 38, 38, 0.06);
    z-index: 103;
  }
}

.nav-toc {
  @media only screen and (max-width: 992px) {
    left: 0;
    transform: translateX(-100%);

    &.toc-open-mobile {
      transform: translateX(0);
    }
  }

  .standard-nav {
    width: 320px;
    padding: 24px;
    z-index: 9;

    @media only screen and (max-width: 1248px) {
      width: 260px;
    }

    .select-dropdown {
      svg {
        right: 4px;
      }
    }
  }

  .toc-list {
    flex: 1;
    position: relative;
    z-index: 8;
    overflow: auto;

    .accordion-content {
      padding-left: 0;
    }

    a {
      display: flex;
      align-items: center;
      position: relative;
      color: var(--off_black);
      font-size: 13px;
      line-height: 1.3;
      text-decoration: none;
      padding: 12px 24px;
      opacity: 0.65;
      transition: var(--default-transition);

      > span {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        // text-transform: lowercase;

        // &:first-letter {
        //   text-transform: uppercase;
        // }
      }

      &:hover,
      &:focus {
        color: var(--off_black);
        opacity: 1;

        svg path {
          fill: var(--off_black);
          transition: var(--default-transition);
        }
      }

      svg {
        position: absolute;
        left: 0;
        transform: rotate(-90deg);
      }

      &.is-accordion.active,
      &.accordion-toggle.active {
        &:before {
          display: none;
        }
      }

      &.active {
        font-family: var(--font-stack-bld);
        opacity: 1;

        &:before {
          content: "";
          position: absolute;
          top: 6px;
          left: 0;
          bottom: 6px;
          width: 4px;
          background-color: var(--blue);
        }

        svg {
          transform: rotate(0);

          path {
            fill: var(--off_black);
          }
        }
      }
    }
  }

  .footer-options {
    width: 320px;
    border-top: 1px solid var(--lt_gray);
    padding: 12px 0;
    z-index: 9;

    @media only screen and (max-width: 1248px) {
      width: 260px;
    }

    a {
      position: relative;
      display: flex;
      align-items: center;
      color: var(--dk_gray);
      font-size: 13px;
      text-decoration: none;
      padding: 12px 24px;
      transition: var(--default-transition);

      &:hover,
      &:focus {
        color: var(--off_black);

        .line {
          background-color: var(--off_black);
        }
      }

      &.go-back {
        &:hover,
        &:focus {
          padding-left: 40px;

          .line {
            width: 24px;
          }
        }
      }

      .line {
        position: absolute;
        left: 0;
        width: 16px;
        height: 1px;
        background-color: var(--dk_gray);
        transition: var(--default-transition);
      }
    }
  }
}

.main-content {
  position: absolute;
  top: 0;
  left: 320px;
  right: 320px;
  bottom: 0;
  transition: var(--long-transition);
  box-shadow: 0px 0px 144px 64px rgba(38, 38, 38, 0.06);
  
  z-index: 102;

  &.ghosted {
    .standard-header,
    .standard-content,
    .standard-footnotes {
      opacity: 0.65;
    }
  }

  @media only screen and (max-width: 1248px) {
    left: 260px;
    right: 260px;
  }

  @media only screen and (max-width: 992px) {
    left: 0;
    right: 0;
  }

  .back-to-top-wrapper {
    position: fixed;
    bottom: 24px;
    right: 344px;
    transition: var(--long-transition);

    @media only screen and (max-width: 1248px) {
      right: 284px;
    }

    @media only screen and (max-width: 992px) {
      right: 24px;
    }
  }

  .back-to-top {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: translateY(96px) scale(1);
    transition: var(--long-transition);

    &.shown {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
      transform: translateY(0) scale(1);

      &:focus,
      &:hover {
        transform: translateY(0) scale(1.04);
      }
    }
  }

  .inner-container {
    width: 100%;
    max-width: 720px;
    margin: auto;
  }

  &.zen {
    left: 0;
    right: 0;

    .action-bar,
    .standard-footnotes {
      width: 100%;
    }

    &.side-closed {
      right: 0;

      .action-bar,
      .standard-footnotes {
        width: 100%;
      }

      .back-to-top-wrapper {
        right: 24px;
      }
    }

    &.side-open {
      right: 320px;

      @media only screen and (max-width: 1248px) {
        right: 260px;
      }

      .action-bar,
      .standard-footnotes {
        width: calc(100% - 320px);

        @media only screen and (max-width: 1248px) {
          width: calc(100% - 260px);
        }
      }
    }
  }

  &.side-closed {
    right: 0;

    .action-bar,
    .standard-footnotes {
      width: calc(100% - 320px);

      @media only screen and (max-width: 1248px) {
        width: calc(100% - 260px);
      }
    }

    .back-to-top-wrapper {
      right: 24px;
    }
  }

  .standard-footnotes {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--lt_gray);
    padding-top: 48px;

    .label-title {
      font-family: var(--font-stack-bld);
      font-size: 13px;
      color: var(--md_gray);
    }

    .footnote-item {
      display: flex;
      align-content: baseline;
      margin-left: 80px;
      padding: 12px 0;
    }

    .footnote-ref {
      text-align: right;
      padding-right: 24px;
      font-family: var(--font-stack-bld);
      font-size: 11px;
    }

    .footnote-content {
      color: var(--dk_gray);
      font-size: 12px;
      line-height: 1.6;
    }
  }

  .action-bar {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 640px);
    min-width: 100%;
    padding: 24px 64px;
    transition: var(--long-transition);
    z-index: 103;
    
    height: 50px;
    @media only screen and (max-width: 1248px) {
      width: calc(100% - 520px);
      padding: 24px 40px;
    }

    .search-btn {
      margin: 0 !important;
    }

    @media only screen and (max-width: 992px) {
      width: 100%;

      .search-btn {
        justify-content: center;
        width: 32px;
        padding: 0 !important;
      }
    }

    &.ghost {
      // background-color: transparent;
      background: linear-gradient(95.47deg, #182b62 0%, #05a1f0 100%);
      .btn {
        color: rgba(255, 255, 255, 0.65);
        background-color: rgba(255, 255, 255, 0.12);

        &:hover,
        &:focus {
          color: var(--white);

          svg path {
            opacity: 1;
          }
        }

        svg path {
          opacity: 0.65;
          fill: var(--white);
        }
      }
    }

    .btn {
      &:not(:first-child) {
        margin-left: 8px;
      }

      &.first,
      &.last {
        transition: var(--long-transition);
        z-index: 1000;
      }

      &.first {
        position: absolute;
        left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &.last {
        position: absolute;
        right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .hide-btn {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }

    svg path {
      transition: var(--default-transition);
    }

    .toggle-side-panel {
      transition: var(--default-transition);
      margin-top: 2px;
    }

    .rotate180 {
      transform: rotate(180deg);
      margin-top: -2px;
    }

    .rotate0 {
      transform: rotate(0);
      margin-top: 2px;
    }
  }

  .standard-header {
    
    top:50px;
    height:120px;
    width: calc(100% - 640px);
    min-width: 100%;
    // max-width: 720px;
    background: linear-gradient(95.47deg, #182b62 0%, #05a1f0 100%);
    padding: 24px 64px;
    //margin-top: -80px;

    @media only screen and (max-width: 1248px) {
      width: calc(100% - 520px);
      padding: 24px 40px;
    }

    .title-group {
      position: absolute;
      z-index: 12;
    }

    img {
      z-index: 11;
    }

    p,
    h3 {
      color: var(--white);
    }

    p {
      margin-bottom: 4px;
      opacity: 0.4;
    }
  }

  .standard-content {
    position:absolute;
    background-color: var(--white);
    top:0px;
    margin-top: 170px;
    bottom: 0;
    width: calc(100% - 640px);
    min-width: 100%;
    padding: 24px 64px;
    overflow-y: auto;
    overflow-x: hidden;

    @media only screen and (max-width: 1248px) {
      width: calc(100% - 520px);
      padding: 24px 40px;
    }

    .paragraph-block {
      margin-left: 80px;

      @media only screen and (max-width: 1248px) {
        margin-left: 40px;
      }
    }

    .html-content {
      * {
        margin: 0 !important;
        text-align: left !important;
        text-indent: 0 !important;
        line-height: 1.4 !important;
      }

      h1,
      h2,
      h3 {
        font-family: var(--font-stack-bld);

        a:not([href]) {
          font-family: var(--font-stack-bld) !important;
        }
      }

      h1,
      h2,
      h3,
      p,
      ul,
      ol {
        a:not([href]) {
          font-family: inherit;
          font-size: inherit;
          color: inherit;
          line-height: inherit;
          transition: none;

          &:focus,
          &:hover {
            font-family: inherit;
            font-size: inherit;
            color: inherit;
            line-height: inherit;
            text-decoration: none;
          }
        }

        transition: var(--default-transition);
      }

      p,
      ul,
      ol {
        .popover .popover-hover {
          color: var(--dk_gray);

          &:focus,
          &:hover {
            color: var(--brand);
            text-decoration: none;
          }
        }
      }

      strong .popover .popover-hover {
        color: var(--off_black);
      }

      a[href*="ftn"] {
        position: relative;
        top: -2px;
        color: var(--off_black);
        font-family: var(--font-stack-bld);
        font-size: 11px;
        padding: 0 2px 0 4px;
      }

      a[data-ref-id], a[data-ftn-ref] {
        color: var(--blue) !important;
        transition: var(--default-transition) !important;
        cursor: pointer;

        &:hover,
        &:focus {
          color: var(--brand) !important;
          text-decoration: underline !important;
        }
      }

      h1 {
        font-size: 20px;
        line-height: 1.2;
        padding: 32px 0 !important;
      }

      h2 {
        font-size: 18px;
        line-height: 1.2;
        padding: 24px 0 !important;
      }

      h3, h4 {
        font-size: 14px;
        line-height: 1.3;
        padding: 16px 0 !important;
      }

      h3 {
        font-weight: bolder;
      }

      h4 {
        font-style: italic;
      }

      p,
      ul,
      ol {
        font-family: var(--font-stack-reg);
        font-size: 14px;
        color: var(--dk_gray);
        line-height: 1.6 !important;
        // padding-left: 80px !important;
        margin-bottom: 12px !important;

        @media only screen and (max-width: 1248px) {
          // padding-left: 40px !important;
        }
      }

      ul,
      ol {
        padding-left: 20px;

        li {
          margin-left: 20px !important;
        }
      }

      ul[type="disc"] {
        li {
          list-style: disc;
        }
      }

      ul[type="circle"] {
        li {
          list-style: circle;
        }
      }
      
      ol {
        counter-reset: list;
      }
      ol > li {
        list-style: none;
        text-indent: -20px !important;
      }
      
      ol[type='i'].level-4 > li {  padding-left: 18px;   }
      ol[type='a']  li 
      { ol li 
        { padding-left: 0px;}}

      ol[type='1'] {
        li {
          list-style:decimal;
          
        }
      }

      ol[type='a'] > li:before {
        content: "(" counter(list, lower-alpha) ") ";
        counter-increment: list;
      }
      
      ol[type='i'] > li:before {
        content: "(" counter(list, lower-roman) ") ";
        counter-increment: list;
      }

      ol[type='I']  > li:before {
        content: "(" counter(list, lower-roman) ") ";
        counter-increment: list;
      }

      ol[type='A'] > li:before {
        content: "(" counter(list, lower-alpha) ") ";
        counter-increment: list;
      }



      em {
        font-style: italic;
      }

      sup {
        font-size: 10px;
        position: relative;
        top: -4px;
        padding-left: 1px;
      }

      strong {
        font-family: var(--font-stack-bld) !important;
        color: var(--off_black);
      }

      a {
        color: var(--blue);
        font-family: var(--font-stack-reg) !important;
        font-size: 14px;
        transition: var(--default-transition);

        &:hover,
        &:focus {
          color: var(--brand);
          text-decoration: underline;
        }
      }

      table {
        margin-left: 100px !important;

        @media only screen and (max-width: 1248px) {
          margin-left: 76px !important;
        }

        @media only screen and (max-width: 768px) {
          margin-left: 60px !important;
        }
        tr {
          padding: 10px;
          width: 100%;
          td {
            padding: 10px;
            border: 1px var(--gray) solid;
          }
        }
        tbody tr td {
          vertical-align: top;
        }
      }

      em .popover {
       display: inherit;
      }
    }

    &.scale-font .html-content {
      h1 {
        font-size: 36px;
      }

      h2 {
        font-size: 28px;
      }

      h3 {
        font-size: 22px;
      }

      p,
      a,
      ul,
      ol {
        font-size: 18px;
      }

      a[href*="ftn"] {
        font-size: 14px;
      }

      .popover {
        .popover-content {
          width: 380px;
          font-size: 18px;
        }
      }

      .paragraph-wrapper {
        .paragraph-side-content {
          .ref {
            font-size: 15px;
          }
        }
      }
    }

    .paragraph-wrapper {
      display: flex;
      align-items: baseline;

      &:hover {
        .btn {
          opacity: 1 !important;
        }
      }

      .paragraph-side-content {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 120px;
        padding-right: 24px;
        position: relative;

        @media only screen and (max-width: 1248px) {
          width: 96px;
        }

        @media only screen and (max-width: 768px) {
          width: 80px;
        }

        .ref {
          font-size: 13px;
          font-family: var(--font-stack-bld);
          color: var(--md_gray);
          margin-bottom: 12px !important;
          transition: var(--default-transition);
        }

        .btn {
          opacity: 0;
        }

        .btn.btn-sq{
          position: absolute;
          top: -6px;
          left: 2px;
          }
      }

      p {
        flex: 1;
      }
    }

    .wrap-paragraph {
      text-indent: -25px !important;
    }

    ul.level-2 {
      padding-left: 100px !important;

      @media only screen and (max-width: 1248px) {
        padding-left: 76px !important;
      }

      @media only screen and (max-width: 768px) {
        padding-left: 60px !important;
      }
    }

    .wrap-paragraph.level-3 {
      padding-left: 145px !important;
    }

    .level-3 {
      padding-left: 120px !important;

      @media only screen and (max-width: 1248px) {
        padding-left: 96px !important;
      }

      @media only screen and (max-width: 768px) {
        padding-left: 80px !important;
      }
    }

    .wrap-paragraph.level-4 {
      padding-left: 170px !important;
    }

    .level-4 {
      padding-left: 144px !important;

      @media only screen and (max-width: 1248px) {
        padding-left: 120px;
      }

      @media only screen and (max-width: 768px) {
        padding-left: 104px;
      }
    }

    .wrap-paragraph.level-5 {
      padding-left: 194px !important;
    }

    .level-5 {
      padding-left: 168px !important;

      @media only screen and (max-width: 1248px) {
        padding-left: 144px;
      }

      @media only screen and (max-width: 768px) {
        padding-left: 120px;
      }
    }
  }
}

.side-content {
  position: relative;

  @media only screen and (max-width: 992px) {
    right: 0;
    transform: translateX(100%);

    &.side-open-mobile {
      transform: translateX(0);
    }
  }

  .nav-tabs {
    width: 320px;
    padding: 24px;
    z-index: 9;
    overflow: auto;

    @media only screen and (max-width: 1248px) {
      width: 260px;
    }
  }

  .tab-content {
    flex: 1;
    overflow: auto;

    &.resource-list {
      padding: 0;
    }
  }

  .resource-section {
    margin-bottom: 4px;
  }

  .section-header {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 24px;
    background-color: var(--lt_gray);
    padding-left: 24px;
    z-index: 101;

    .title {
      color: var(--dk_gray);
      font-size: 11px;
      font-family: var(--font-stack-bld);
      line-height: 15px;
      text-transform: uppercase;
      letter-spacing: 2px;
    }

    .btn {
      height: 24px;
      font-size: 12px;
      font-family: var(--font-stack-med);
      line-height: 15px;
      color: var(--dk_gray);
      border-radius: 0;
      border-right: 1px solid var(--gray);
      padding: 0 8px;

      &:focus,
      &:hover {
        background-color: var(--gray);
        transform: scale(1);

        &:disabled,
        &[disabled] {
          background-color: var(--lt_gray);
        }
      }
    }

    .toggle-section {
      width: 24px;
      height: 24px;
      transition: var(--default-transition);

      &:focus,
      &:hover {
        background-color: var(--gray);
      }
    }
  }

  .section-content {
    padding: 24px;
    overflow: hidden;
  }

  .resource-input-item {
    border-bottom: 1px solid var(--gray);
    padding-bottom: 12px;
    margin-bottom: 12px;

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    label {
      width: 48px;
      padding-right: 12px;
    }
  }

  .remove-resource-file {
    position: absolute;
    top: -8px;
    right: -12px;
    z-index: 99;
  }

  .empty-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;

    .bucket-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 112px;
      height: 112px;
      background-color: var(--lt_gray);
      border-radius: 50%;
    }
  }

  img.dots {
    position: initial;
    border-top: 1px solid var(--lt_gray);
    padding-left: 32px;
    padding-top: 32px;
  }
}


.backToSearchBtnStyle {
  opacity:1;
  visibility: visible;
  transition: var(--long-transition);
  transform:translateY(0) scale(1);
  position:fixed;
  bottom:24px;
};

.standard-content .html-content ul[type="disc"] li:not(:last-child) {
  padding-bottom : 5px;  
  };

  .main-content .standard-content .wrap-paragraph.rm-padding-left{
  padding-left:25px !important;
}

.main-content .standard-content .extra-wrap-paragraph
{
  text-indent: -45px !important
}

.main-content .standard-content .level-4.extra-wrap-paragraph
{
  padding-left: 167px !important
}
.main-content .standard-header .title-group{
  max-width: fit-content;
}