.nav-tabs {
  display: flex;

  .nav-tab {
    border-radius: 0;

    &:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    &:not(:last-child) {
      margin-right: 2px;
    }

    &:disabled,
    &[disabled] {
      opacity: 0.65;
      cursor: not-allowed;

      &:focus,
      &:hover {
        transform: scale(1);
      }
    }
  }

  > .select-dropdown {
    border-radius: 0;

    .nav-tab {
      border-radius: 0;
      margin: 0 !important;
    }

    &:first-child .nav-tab {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &:last-child .nav-tab {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    &:not(:last-child) {
      margin-right: 2px;
    }
  }
}

.nav-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 56px;
  font-family: var(--font-stack-bld);
  font-size: 14px;
  border: none;
  border-radius: 6px;
  padding: 12px 24px;
  cursor: pointer;
  transition: var(--default-transition);

  &:focus,
  &:hover {
    transform: scale(1.04);
  }

  &.xs {
    height: 24px;
    font-size: 11px;
    padding: 2px 8px;

    &:focus,
    &:hover {
      transform: scale(1);
    }
  }

  &.sm {
    height: 32px;
    font-size: 13px;
    padding: 4px 12px;
  }

  &.primary {
    color: var(--white);
    background-color: var(--brand);
  }

  &.secondary {
    color: var(--md_gray);
    background-color: var(--lt_gray);

    &:hover,
    &:focus {
      color: var(--dk_gray);
    }
  }

  &.ghost {
    color: rgba(255, 255, 255, 0.65);
    background-color: rgba(255, 255, 255, 0.12);

    &:hover,
    &:focus {
      color: var(--white);
    }
  }
}

.tab-content {
  padding: 0 24px 24px;
}
