//
// MIXINS
//

@mixin grid-columns($gutter: 24px, $mq-width: "tablet", $mq-type: max, $mq-margin-bottom: 32px) {
  display: flex;

  > .column {
    width: 100%;
    margin: 0 #{$gutter / 2};

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  @include mq($mq-width, $mq-type) {
    flex-direction: column;

    > .column {
      margin: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

$breakpoints: (
  "phone": 400px,
  "phone-wide": 480px,
  "phablet": 560px,
  "tablet-small": 640px,
  "tablet": 768px,
  "tablet-wide": 992px,
  "desktop": 1248px,
  "desktop-wide": 1440px,
);
@mixin mq($width, $type: max) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type == max {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

@mixin clearfix {
  &:before,
  &:after {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin size($width, $height: $width) {
  min-width: $width;
  min-height: $height;
  width: $width;
  height: $height;
}

@mixin rounded($width, $height: $width, $radius: 50%) {
  width: $width;
  height: $height;
  border-radius: $radius;
}

@mixin invisible {
  visibility: hidden;
}

@mixin hidden {
  display: none;

  @include invisible;
}

@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@mixin pos($pos, $t: initial, $r: initial, $b: initial, $l: initial, $z: false) {
  @if $pos {
    position: $pos;
  }
  @if $t {
    top: $t;
  }
  @if $r {
    right: $r;
  }
  @if $b {
    bottom: $b;
  }
  @if $l {
    left: $l;
  }
  @if $z {
    z-index: $z;
  }
}

@mixin antialias {
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin pseudo($display: block, $pos: absolute, $content: "") {
  content: $content;
  display: $display;
  position: $pos;
}

@mixin responsive-ratio($x, $y, $pseudo: false) {
  $padding: unquote(($y / $x) * 100 + "%");

  @if $pseudo {
    &::before {
      @include pseudo($pos: relative);
      width: 100%;
      padding-top: $padding;
    }
  } @else {
    padding-top: $padding;
  }
}

@mixin truncate($truncation-boundary) {
  max-width: $truncation-boundary;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  pointer-events: none;
}

@mixin appearance($val: none) {
  -webkit-appearance: $val;
  -moz-appearance: $val;
  appearance: $val;
}

@mixin fixed-top {
  @include pos($pos: fixed, $t: 0, $r: 0, $l: 0, $z: $zindex-fixed);
}

@mixin fixed-bottom {
  @include pos($pos: fixed, $r: 0, $b: 0, $l: 0, $z: $zindex-fixed);
}

@mixin sticky-top {
  @supports (position: sticky) {
    @include pos($pos: sticky, $t: 0, $z: $zindex-sticky);
  }
}

@mixin padding($t: initial, $r: initial, $b: initial, $l: initial) {
  padding: $t $r $b $l;
}

@mixin margin($t: initial, $r: initial, $b: initial, $l: initial) {
  margin: $t $r $b $l;
}

@mixin button-shadow($color: $off_black, $alpha: 0.24) {
  box-shadow: 0 4px 16px rgba($color, $alpha);
}
