@import "./mixins.scss";

.file-upload {
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  background-color: transparent;
  border: 2px dashed var(--md_blue);
  border-radius: 6px;
  padding: 12px 48px;
  cursor: pointer;
  transition: var(--default-transition);

  &:hover,
  &:focus {
    transform: scale(1.012);
    background-color: rgba(255, 255, 255, 0.08);

    img {
      transform: rotate(0) scale(1.1) translateY(2px);
    }
  }

  &.resource-file {
    justify-content: center;
    background-color: var(--lt_gray);
    border: 2px dashed var(--gray);
    padding: 12px;
    text-align: center;

    &:hover,
    &:focus {
      border-color: var(--md_gray);
    }

    &.has-file {
      justify-content: initial;
      background-color: var(--white);
      border: transparent;
      box-shadow: var(--default-shadow);

      &:hover,
      &:focus {
        box-shadow: var(--card-shadow-lifted);
      }

      .file-name {
        @include truncate(144px);

        @media only screen and (max-width: 1248px) {
          @include truncate(88px);
        }
      }
    }
  }

  &.has-error {
    border-color: var(--red);
  }

  &.has-file {
    border: 2px solid var(--green);

    img {
      transform: rotate(0) scale(1.1) translateY(2px);
    }
  }

  img {
    width: 48px;
    transform: rotate(-15deg) translateY(2px);
    transition: var(--med-transition);
  }

  h6 {
    font-size: 16px;
  }

  input {
    @include pos(absolute, 0, 0, 0, 0);
    opacity: 0;
    cursor: pointer;
  }
}

.form-input {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 56px;
  font-family: var(--font-stack-bld);
  font-size: 14px;
  color: var(--white);
  border: 2px solid transparent;
  border-radius: 6px;
  padding: 12px 24px;
  transition: var(--default-transition);

  &:hover {
    border-color: rgba(255, 255, 255, 0.12);
  }

  &:focus {
    border-color: rgba(255, 255, 255, 0.65);
  }

  &.has-error {
    border-color: var(--red);
  }

  &.sm {
    height: 32px;
    font-size: 13px;
    padding: 4px 12px;
  }

  &.primary {
    color: var(--white);
    background-color: var(--brand);
  }

  &.secondary {
    color: var(--md_gray);
    background-color: var(--lt_gray);

    &:hover {
      border-color: var(--gray);
    }

    &:focus {
      border-color: var(--md_gray);
    }

    &:hover,
    &:focus {
      color: var(--dk_gray);
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.24);
    }

    &:-ms-input-placeholder {
      color: rgba(0, 0, 0, 0.24);
    }

    &::-ms-input-placeholder {
      color: rgba(0, 0, 0, 0.24);
    }
  }

  &.ghost {
    background-color: rgba(255, 255, 255, 0.12);
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.65);
  }

  &:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.65);
  }

  &::-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.65);
  }
}

.select-dropdown {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;

  svg {
    @include unselectable;
    position: absolute;
    right: 12px;
  }
}

.ifac-date-picker.ghost {
  .react-date-picker {
    &__wrapper {
      display: flex;
      align-items: center;
      height: 32px;
      background-color: rgba(255, 255, 255, 0.12);
      border: 2px solid transparent;
      border-radius: 6px;
      padding: 4px;
      transition: var(--default-transition);
    }

    &__inputGroup {
      display: flex;
      align-items: center;

      &__input {
        font-family: var(--font-stack-bld);
        font-size: 14px;
        color: var(--white);

        &::placeholder {
          color: rgba(255, 255, 255, 0.65);
        }

        &:-ms-input-placeholder {
          color: rgba(255, 255, 255, 0.65);
        }

        &::-ms-input-placeholder {
          color: rgba(255, 255, 255, 0.65);
        }
      }

      &__divider {
        opacity: 0.4;
        transform: translateY(1px);
      }
    }

    &__button {
      svg {
        stroke: var(--white);
        transition: var(--default-transition);

        &:hover,
        &:focus,
        &:hover:focus,
        &:active,
        &:active:focus {
          stroke: var(--blue);
        }
      }
    }
  }

  .react-calendar {
    border: none;
    border-radius: 6px;
    overflow: hidden;
    margin-top: 8px;

    &__navigation {
      margin-bottom: 0;
    }

    button {
      font-family: var(--font-stack-bld) !important;
      font-size: 14px;
      transition: var(--default-transition);
    }

    &__month-view__weekdays__weekday {
      color: var(--dk_gray);
    }
  }
}

.custom-input {
  display: flex;
  align-items: center;
  height: 32px;

  input[type="checkbox"],
  input[type="radio"] {
    display: none;

    &:hover {
      & + label {
        &:before {
          background-color: rgba(255, 255, 255, 0.2);
          border-color: var(--white);
        }
      }
    }

    & + label {
      &:after {
        opacity: 0;
      }

      &:focus {
        // outline: -webkit-focus-ring-color auto 1px;

        &:before {
          background-color: rgba(255, 255, 255, 0.2);
          border-color: var(--white);
        }
      }
    }

    &:checked {
      & + label {
        &:after {
          opacity: 1;
        }

        &:before {
          border-color: var(--white);
        }
      }

      &:hover {
        & + label {
          &:before {
            background-color: rgba(255, 255, 255, 0.2);
            border-color: var(--white);
          }
        }
      }
    }
  }

  label {
    position: relative;
    display: inline-block;
    padding-left: 34px;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:before,
    &:after {
      content: "";
      position: absolute;
      display: inline-block;
    }

    &:before {
      width: 24px;
      height: 24px;
      top: 2px;
      left: 0;
      background-color: transparent;
      border: 2px solid rgba(255, 255, 255, 0.65);
      transition: var(--default-transition);
    }

    &:after {
      transition: var(--default-transition);
    }

    &:focus {
      outline: 0;
    }

    // @include mq("tablet") {
    //   font-size: 14px;
    //   padding-left: 32px;

    //   &:before {
    //     @include size(24px);
    //     top: -3px;
    //   }
    // }
  }

  input[type="checkbox"] {
    & + label {
      &:before {
        border-radius: 6px;
      }

      &:after {
        top: 9px;
        left: 6px;
        width: 12px;
        height: 6px;
        border-left: 2px solid var(--white);
        border-bottom: 2px solid var(--white);
        transform: rotate(-45deg);
        transition: var(--default-transition);

        // @include mq("tablet") {
        //   left: 6px;
        //   width: 12px;
        //   height: 7px;
        // }
      }
    }
  }

  input[type="radio"] {
    & + label {
      &:before {
        border-radius: 50%;
      }

      &:after {
        width: 14px;
        height: 14px;
        top: 7px;
        left: 5px;
        background-color: var(--white);
        border-radius: 50%;
        transition: var(--default-transition);
      }

      // @include mq("tablet") {
      //   &:before {
      //     border-radius: 12px;
      //   }

      //   &:after {
      //     top: 4px;
      //     left: 7px;
      //     @include rounded(10px);
      //     background-color: $off_black;
      //     transition: $default-transition;
      //   }
      // }
    }
  }
}

.custom-input-dk {
  display: flex;
  align-items: center;
  height: 24px;

  input[type="checkbox"],
  input[type="radio"] {
    display: none;

    &:hover {
      & + label {
        &:before {
          background-color: rgba(0, 0, 0, 0.2);
          border-color: var(--off_black);
        }
      }
    }

    & + label {
      &:after {
        opacity: 0;
      }

      &:focus {
        // outline: -webkit-focus-ring-color auto 1px;

        &:before {
          background-color: rgba(0, 0, 0, 0.2);
          border-color: var(--off_black);
        }
      }
    }

    &:checked {
      & + label {
        &:after {
          opacity: 1;
        }

        &:before {
          border-color: var(--off_black);
        }
      }

      &:hover {
        & + label {
          &:before {
            background-color: rgba(0, 0, 0, 0.2);
            border-color: var(--off_black);
          }
        }
      }
    }
  }

  label {
    position: relative;
    display: inline-block;
    padding-left: 34px;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:before,
    &:after {
      content: "";
      position: absolute;
      display: inline-block;
    }

    &:before {
      width: 24px;
      height: 24px;
      top: 2px;
      left: 0;
      background-color: transparent;
      border: 2px solid rgba(0, 0, 0, 0.65);
      transition: var(--default-transition);
    }

    &:after {
      transition: var(--default-transition);
    }

    &:focus {
      outline: 0;
    }

    // @include mq("tablet") {
    //   font-size: 14px;
    //   padding-left: 32px;

    //   &:before {
    //     @include size(24px);
    //     top: -3px;
    //   }
    // }
  }

  input[type="checkbox"] {
    & + label {
      &:before {
        border-radius: 6px;
      }

      &:after {
        top: 9px;
        left: 6px;
        width: 12px;
        height: 6px;
        border-left: 2px solid var(--off_black);
        border-bottom: 2px solid var(--off_black);
        transform: rotate(-45deg);
        transition: var(--default-transition);

        // @include mq("tablet") {
        //   left: 6px;
        //   width: 12px;
        //   height: 7px;
        // }
      }
    }
  }

  input[type="radio"] {
    & + label {
      &:before {
        border-radius: 50%;
      }

      &:after {
        width: 8px;
        height: 8px;
        top: 10px;
        left: 8px;
        background-color: var(--off_black);
        border-radius: 50%;
        transition: var(--default-transition);
      }

      // @include mq("tablet") {
      //   &:before {
      //     border-radius: 12px;
      //   }

      //   &:after {
      //     top: 4px;
      //     left: 7px;
      //     @include rounded(10px);
      //     background-color: $off_black;
      //     transition: $default-transition;
      //   }
      // }
    }
  }
}
