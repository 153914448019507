.heading-block {
  width: 60%;
  height: 28px;
  background-color: var(--md_gray);
  background-image: linear-gradient(to right, var(--md_gray) 0%, #ccc 20%, var(--md_gray) 40%, var(--md_gray) 100%);
  background-size: 1400px 104px;
  border-radius: 6px;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
}

.paragraph-block {
  height: 12px;
  background: var(--gray);
  background: var(--gray);
  background-image: linear-gradient(to right, var(--gray) 0%, #ccc 20%, var(--gray) 40%, var(--gray) 100%);
  background-size: 1400px 104px;
  border-radius: 6px;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;

  &.sm {
    width: 65%;
  }

  &.md {
    width: 75%;
  }

  &.lg {
    width: 88%;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -700px 0;
  }
  100% {
    background-position: 700px 0;
  }
}
