.backdrop {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999;

  &.is-video-backdrop {
    background-color: rgba(0, 0, 0, 0.65);
    backdrop-filter: blur(3px);
  }

  .modal {
    display: flex;
    flex-direction: column;
    max-width: 440px;
    background-color: var(--white);
    border-radius: 12px;
    padding: 24px 32px;
    margin: 0 24px;
    cursor: initial;

    &.is-video {
      max-width: 720px;
      width: 100%;
      background-color: transparent;
      padding: 0;

      h4 {
        color: var(--white);
      }

      video {
        border-radius: 12px;
        overflow: hidden;
      }
    }

    p {
      color: var(--dk_gray);
    }
  }
}
