:root {
  /* Colors */
  --brand: #162b64;
  --blue: #05a2f0;
  --lt_blue: #9de1f7;
  --md_blue: #749be8;
  --red: #b54040;
  --lt_red: #fcc5c5;
  --pink: #feecec;
  --orange: #f18c0f;
  --green: #00b73a;

  --black: #000000;
  --off_black: #262626;
  --white: #ffffff;
  --off_white: #f9fafa;

  --lt_gray: #f1f2f6;
  --gray: #dedfe2;
  --md_gray: #726E6E;
  --dk_gray: #727780;
  --gray_blue: #b9bfd1;

  --black_rgb: 38, 38, 38;

 /* Fonts */
 --font-stack-reg: "Poppins-Regular", Arial, sans-serif;
 --font-stack-itl: "Poppins-Italic", Arial, sans-serif;
 --font-stack-med: "Poppins-Medium", Arial, sans-serif;
 --font-stack-medItl: "Poppins-MediumItalic", Arial, sans-serif;
 --font-stack-bld: "Poppins-SemiBold", Arial, sans-serif;
 --font-stack-bldItl: "Poppins-SemiBoldItalic", Arial, sans-serif;

  /* Shadows */
  --default-shadow: 0 8px 20px 0 rgba(var(--black_rgb), 0.12);
  --default-lifted-shadow: 0 10px 28px 0 rgba(var(--black_rgb), 0.24);
  --header-shadow: 0 4px 40px 0 rgba(var(--black_rgb), 0.08);
  --card-shadow: 0 12px 40px 0 rgba(var(--black_rgb), 0.08);
  --card-shadow-lifted: 0 16px 32px 0 rgba(var(--black_rgb), 0.16);

  /* Transitions */
  --default-transition: all 0.2s cubic-bezier(0.33, 0.66, 0.66, 1);
  --med-transition: all 0.4s cubic-bezier(0.33, 0.66, 0.66, 1);
  --long-transition: all 0.6s cubic-bezier(0.33, 0.66, 0.66, 1);
}

// For Sass

$brand: #162b64;
$blue: #05a2f0;
$lt_blue: #9de1f7;
$md_blue: #749be8;
$red: #b54040;
$lt_red: #fcc5c5;
$pink: #feecec;
$orange: #f18c0f;
$green: #00b73a;

$black: #000000;
$off_black: #262626;
$white: #ffffff;
$off_white: #f9fafa;

$lt_gray: #f1f2f6;
$gray: #dedfe2;
$md_gray: #726E6E;
$dk_gray: #727780;
$gray_blue: #b9bfd1;
