.accordion-toggle {
  display: flex;
  align-items: center;
  font-family: var(--font-stack-med);
  font-size: 13px;
  line-height: 20px;
  color: var(--dk_gray);
  padding: 8px 0;
  transition: var(--default-transition);

  &:hover,
  &:focus {
    color: var(--off_black);

    &.active {
      .btn svg path {
        fill: var(--blue);
      }
    }

    .btn {
      color: var(--off_black);
      transform: scale(1.04);

      svg path {
        fill: var(--dk_gray);
      }
    }
  }

  &.active {
    font-family: var(--font-stack-bld);
    color: var(--off_black);

    .btn {
      color: var(--blue);
    }

    svg path {
      fill: var(--blue);
    }
  }

  .btn {
    background-color: transparent;
    font-size: 17px;
    margin-right: 4px;
  }
}

.accordion-content {
  border-left: 1px solid var(--lt_gray);
  padding-left: 15px;
  margin-left: 12px;
  overflow: hidden;

  .accordion-content-inner {
    margin-bottom: 12px;
  }

  p {
    color: var(--md_gray);
    font-size: 12px;
    line-height: 1.5;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    a {
      color: var(--blue);

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}
